import { BrowserPrint, Zebra } from '@/plugins/zebra/index'
import { mapActions } from 'vuex'

const zebraPrint = {
  data() {
    return {
      device: null,
      printerReady: false,

      //Dev
      devEnvironment: false,
    }
  },
  destroyed() {
    this.setCheckPrinter(false)
    this.setPrinterConnected(false)
  },

  methods: {
    ...mapActions({
      setHasPrinter: 'printer/setHasPrinter',
      setCheckPrinter: 'printer/setCheckPrinter',
      setPrinterConnected: 'printer/setPrinterConnected',
      setLoading: 'loading/setLoading',
      setError: 'error/setError',
    }),

    async initZebra() {
      await this.checkPrinterParam()
      await this.connectPrinter(false)
    },

    async print(text) {
      if (this.device && this.printerReady) {
        this.setLoading(true)

        let hasConnection = false
        this.device.isPrinterReady().then(() => {
          this.device.send(text, () => {      
            hasConnection = true
            this.setLoading(false)
          }, () => {
            hasConnection = false
          })
        }).catch(() => {
          hasConnection = false
        })

        setTimeout(() => {
          if (!hasConnection) {
            this.setPrinterReady(null, true)
          }
        }, 2000)

      } else {
        this.setPrinterReady(null, true)
      }
    },

    showConnectionErrorMessage() {
      this.setError('A impressora não está conectada ou não está respondendo. Conecte a impressora ou peça ajuda para alguém do time de Suporte.')
    },

    setPrinterReady(device, showConnectionError = false) {
      this.device = device
      this.printerReady = !!device
      this.setLoading(false)

      if (showConnectionError && !this.printerReady) {
        this.showConnectionErrorMessage()
      }

      if (!this.printerReady) {
        this.setPrinterNotReadyOnStore()
      } else if (this.printerReady){
        this.setPrinterIsReadyOnStore()

      }
    },

    async connectPrinter(showConnectionError = true) {
      this.setLoading(true)
      
      let that = this
      let device = null

      await BrowserPrint.getLocalDevices(devicesList => {       
        if (devicesList && devicesList.printer && devicesList.printer.length > 0) {
          let usbDevices = devicesList.printer.filter(d => d.connection === 'usb')
          if (usbDevices.length > 0) {
            device = new Zebra.Printer(usbDevices[0])
          }
          this.setHasPrinter(true)
          that.setPrinterReady(device, showConnectionError)
          return
        }
        this.setHasPrinter(true)
        that.setPrinterReady(device, showConnectionError)
        return
      }, () => {
        this.setHasPrinter(false)
        that.setPrinterReady(device, showConnectionError)
        return
      })
    },

    async disconnectPrinter() {
      this.setPrinterReady(null, false)
    },

    setPrinterIsReadyOnStore() {
      this.setPrinterConnected(true)
    },

    setPrinterNotReadyOnStore() {
      if (!this.devEnvironment) {
        this.setPrinterConnected(false)
      }
    },

    //Helpers Dev
    checkPrinterParam() {
      const isProduction = process.env.VUE_APP_MODE === 'production'
      if (!isProduction) {
        if (this.$route.query && this.$route.query.print) {
          this.devEnvironment = this.$route.query.print === 'true' ? true : false
          if (this.devEnvironment) {
            this.setPrinterIsReadyOnStore()
          }
        }
      }
    },
  },
}

export default zebraPrint