/* eslint-disable */
let finishedFunction, response, arr
const BrowserPrint = function() {
    function b(a, b) {
        var d = new XMLHttpRequest;
        "withCredentials" in d ? d.open(a, b, !0) : "undefined" != typeof XDomainRequest ? (d = new XDomainRequest, d.open(a, b)) : d = null;
        return d
    }

    function h(a, b, d) {
        void 0 === b && (b = e.defaultSuccessCallback);
        void 0 === d && (d = e.defaultErrorCallback);
        return c(a, b, d)
    }

    function c(a, b, d) {
        a.onreadystatechange = function() {
            a.readyState === XMLHttpRequest.DONE && 200 === a.status ? "" === a.responseType ? b(a.responseText) : b(a.response) : a.readyState === XMLHttpRequest.DONE && (d ? d(a.response) :
                console.log("error occurred with no errorCallback set."))
        };
        return a
    }
    var e = {},
        k = {},
        p = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    navigator.userAgent.indexOf("Trident/7.0");
    var l = "http://127.0.0.1:9100/";
    p && "https:" === location.protocol && (l = "https://127.0.0.1:9101/");
    e.Device = function(a) {
        var m = this;
        this.name = a.name;
        this.deviceType = a.deviceType;
        this.connection = a.connection;
        this.uid = a.uid;
        this.version = 2;
        this.provider = a.provider;
        this.manufacturer = a.manufacturer;
        this.readRetries = 0;
        this.sendErrorCallback = function(d) {};
        this.sendFinishedCallback = function(d) {};
        this.readErrorCallback = function(d) {};
        this.readFinishedCallback = function(d) {};
        this.send = function(d, a, f) {
            var g = b("POST", l + "write");
            g && (void 0 !== m && (void 0 === a && (a = m.sendFinishedCallback), void 0 === f && (f = m.sendErrorCallback)), c(g, a, f), g.send(JSON.stringify({
                device: {
                    name: this.name,
                    uid: this.uid,
                    connection: this.connection,
                    deviceType: this.deviceType,
                    version: this.version,
                    provider: this.provider,
                    manufacturer: this.manufacturer
                },
                data: d
            })))
        };
        this.sendUrl = function(d, a, f, e) {
            var g = b("POST", l + "write");
            g && (c(m, g, a, f), d = {
                device: {
                    name: this.name,
                    uid: this.uid,
                    connection: this.connection,
                    deviceType: this.deviceType,
                    version: this.version,
                    provider: this.provider,
                    manufacturer: this.manufacturer
                },
                url: d
            }, null != e && void 0 != e && (d.options = e), g.send(JSON.stringify(d)))
        };
        this.sendFile = function(d, a, f) {
            if ("string" === typeof d) e.loadFileFromUrl(d, function(d) {
                m.sendFile(d, a, f)
            }, f);
            else {
                var g = b("POST", l + "write");
                if (g) {
                    g.responseType = "text";
                    h(g, a, f);
                    var c =
                        new FormData,
                        n = {};
                    n.device = m;
                    c.append("json", JSON.stringify(n));
                    c.append("blob", d);
                    g.send(c)
                }
            }
        };
        this.convertAndSendFile = function(d, a, b, c) {
            c || (c = {});
            c.action || (c.action = "print");
            e.convert(d, this, c, a, b)
        };
        this.read = function(d, a) {
            var f = b("POST", l + "read");
            f && (void 0 !== m && (void 0 === d && (d = m.readFinishedCallback), void 0 === a && (a = m.readErrorCallback)), c(f, d, a), f.send(JSON.stringify({
                device: {
                    name: this.name,
                    uid: this.uid,
                    connection: this.connection,
                    deviceType: this.deviceType,
                    version: this.version,
                    provider: this.provider,
                    manufacturer: this.manufacturer
                }
            })))
        };
        this.readUntilStringReceived = function(d, a, b, e, c) {
            c || (c = "");
            void 0 === e && (e = this.readRetries);
            a = function(a, b, e, f, g) {
                return function(c) {
                    if (c && 0 !== c.length) f = 0;
                    else if (0 >= f) {
                        b(g);
                        return
                    }
                    c = g + c;
                    "" !== d && -1 < c.indexOf(d) ? b(c) : a.readUntilStringReceived(d, b, e, f - 1, c)
                }
            }(this, a, b, e, c);
            this.read(a, b)
        };
        this.readAllAvailable = function(a, b, f) {
            this.readUntilStringReceived("", a, b, f)
        };
        this.sendThenRead = function(a, b, f) {
            this.send(a, function(a) {
                return function() {
                    a.read(b, f)
                }
            }(this), f)
        };
        this.sendThenReadUntilStringReceived = function(a, b, f, e, c) {
            this.send(a, function(a) {
                return function() {
                    a.readUntilStringReceived(b, f, e, c)
                }
            }(this), e)
        };
        this.sendThenReadAllAvailable = function(a, b, e, c) {
            this.send(a, function(a) {
                return function() {
                    a.readUntilStringReceived("", b, e, c)
                }
            }(this), e)
        }
    };
    e.defaultSuccessCallback = function() {};
    e.defaultErrorCallback = function() {};
    e.ApplicationConfiguration = function() {
        this.application = {
            version: "1.2.0.3",
            build_number: 3,
            api_level: 2,
            platform: "",
            supportedConversions: {}
        }
    };
    e.getLocalDevices =
        function(a, c, d) {
            var g = b("GET", l + "available");
            g && (finishedFunction = function(b) {
                response = b;
                response = JSON.parse(response);
                for (var c in response)
                    if (response.hasOwnProperty(c) && response[c].constructor === Array)
                        for (arr = response[c], b = 0; b < arr.length; ++b) arr[b] = new e.Device(arr[b]);
                void 0 === d ? a(response) : (response.hasOwnProperty(d) || (response[d] = []), a(response[d]))
            }, h(g, finishedFunction, c), g.send())
        };
    e.getDefaultDevice = function(a, c, d) {
        var g = "default";
        void 0 !== a && null != a && (g = g + "?type=" + a);
        if (a = b("GET", l + g)) finishedFunction =
            function(a) {
                response = a;
                "" === response ? c(null) : (response = JSON.parse(response), a = new e.Device(response), c(a))
            }, a = h(a, finishedFunction, d), a.send()
    };
    e.getApplicationConfiguration = function(a, c) {
        var d = b("GET", l + "config");
        d && (finishedFunction = function(b) {
            response = b;
            "" === response ? a(null) : (response = JSON.parse(response), a(response))
        }, h(d, finishedFunction, c), d.send())
    };
    e.readOnInterval = function(a, b, d) {
        if (void 0 === d || 0 === d) d = 1;
        readFunc = function() {
            a.read(function(c) {
                b(c);
                k[a] = setTimeout(readFunc, d)
            }, function(b) {
                k[a] =
                    setTimeout(readFunc, d)
            })
        };
        k[a] = setTimeout(readFunc, d)
    };
    e.stopReadOnInterval = function(a) {
        k[a] && clearTimeout(k[a])
    };
    e.bindFieldToReadData = function(a, b, d, c) {
        e.readOnInterval(a, function(a) {
            "" !== a && (b.value = a, void 0 !== c && null != c && c())
        }, d)
    };
    e.loadFileFromUrl = function(a, c, d) {
        request = b("get", a);
        console.log("ResponseType: " + request.responseType);
        request.responseType = "blob";
        h(request, c, d);
        request.send()
    };
    e.convert = function(a, c, d, g, f) {
        if (a)
            if ("string" === typeof a) e.loadFileFromUrl(a, function(b) {
                d.fromFormat ||
                    (d.fromFormat = a.substring(a.length - 3));
                e.convert(b, c, d, g, f)
            }, f);
            else {
                var k = b("POST", l + "convert");
                a.type && (a.type.startsWith("image/") || a.type.startsWith("application/")) && (d.fromFormat = a.type.toLowerCase().replace("image/", "").replace("application/", "").replace("x-ms-", ""));
                if (k) {
                    k.responseType = "text";
                    h(k, function(a) {
                        g(JSON.parse(a))
                    }, f);
                    var m = new FormData,
                        n = {};
                    null != d && void 0 !== d && (n.options = d);
                    c && (n.device = c);
                    m.append("json", JSON.stringify(n));
                    m.append("blob", a);
                    k.send(m)
                }
            }
        else f ? f("Resource not specified") :
            e.defaultErrorCallback("Resource not specified")
    };
    return e
}();

function dbg(e) {
    // console.log(e)
}
const Zebra = function() {
    function e(c, d) {
        if (d instanceof g.Printer.Status && l[c.device.uid]) {
            if (d.offline) {
                if (c.errors++, c.errors < c.errorsForOffline) return
            } else c.errors = 0;
            var b = l[c.device.uid].status,
                a = JSON.stringify(b);
            c.status = d;
            statusStr = JSON.stringify(d);
            if (statusStr !== a) c.onchange(b, d)
        }
    }

    function h(c) {
        return 2 !== c.charCodeAt(0) || 3 !== c.charCodeAt(c.length - 1) ? (dbg("Response did not contain proper control characters"), !1) : !0
    }
    var g = {},
        l = {};
    setInterval(function() {
        for (var c in l) l.hasOwnProperty(c) && (c =
            l[c],
            function(d) {
                d.device.getStatus(function(b) {
                    e(d, b)
                }, function(b) {
                    e(d, new g.Printer.Status(""))
                })
            }(c))
    }, 2E3);
    g.Printer = function(c) {
        BrowserPrint.Device.call(this, c);
        g.Printer.Status = function(a) {
            this.raw = a;
            this.isFlagSet = function(a) {
                return "1" === this.raw.charAt(a)
            };
            this.offline = !1;
            a || (a = "");
            a = a.trim();
            h(a) ? (this.offline = !1, this.paperOut = this.isFlagSet(5), this.paused = this.isFlagSet(7), this.headOpen = this.isFlagSet(43), this.ribbonOut = this.isFlagSet(45)) : (this.offline = !0, this.ribbonOut = this.headOpen = this.paused =
                this.paperOut = !1);
            this.isPrinterReady = function() {
                return !(this.paperOut || this.paused || this.headOpen || this.ribbonOut || this.offline)
            };
            this.getMessage = function() {
                return this.isPrinterReady() ? "Ready" : this.offline ? "Offline" : this.paperOut ? "Paper Out" : this.headOpen ? "Head Open" : this.ribbonOut ? "Ribbon Out" : this.paused ? "Paused" : "Ready"
            }
        };
        g.Printer.Info = function(a) {
            if (!a) throw "Invalid Response";
            this.raw = a;
            a = a.trim();
            if (!h(a)) throw "Invalid Response";
            a = a.split(",");
            this.model = a[0].substring(1);
            this.firmware = a[1]
        };
        g.Printer.Configuration = function(a) {
            if (!a) throw "Invalid Response";
            this.raw = a = a.trim();
            this.settings = {};
            if (!h(a)) throw "Invalid Response";
            a = a.replace(String.fromCharCode(2), "");
            a = a.replace(String.fromCharCode(3), "");
            a = a.split("\n");
            for (var f = 0; f < a.length; ++f) {
                var b = a[f].trim(),
                    d = b.substring(20);
                b = b.substring(0, 20).trim();
                this.settings[d] = b
            }
            this.darkness = parseFloat(this.settings.DARKNESS);
            this.printSpeed = parseInt(this.settings["PRINT SPEED"].replace("IPS", "").trim());
            this.printWidth = parseInt(this.settings["PRINT WIDTH"]);
            this.labelLength = parseInt(this.settings["LABEL LENGTH"]);
            this.firmwareVersion = this.settings.FIRMWARE.replace("<-", "").trim();
            this.linkOSVersion = this.settings.hasOwnProperty("LINK-OS VERSION") ? this.settings["LINK-OS VERSION"] : "0"
        };
        var d = this;
        this.configuration = void 0;
        this.device_request_queue = [];
        this.clearRequestQueue = function() {
            var a = this.device_request_queue[0];
            this.device_request_queue = [];
            a && a.started && (this.device_request_queue[0] = a)
        };
        this.Request = function(a, f, b, c, e) {
            this.type = a;
            this.command = f;
            this.received = b;
            this.success = c;
            this.error = function(a) {
                return function(f) {
                    a(f);
                    d.executeNextRequest()
                }
            }(e);
            this.execute = function() {
                this.started = !0;
                "info" == this.type || "config" == this.type || "status" == this.type ? d.sendThenReadUntilStringReceived(this.command, String.fromCharCode(3), this.received, this.error) : d.sendThenReadAllAvailable(this.command, this.received, this.error)
            }
        };
        this.executeNextRequest = function() {
            d.device_request_queue.shift();
            d.executeRequest()
        };
        this.executeRequest = function() {
            dbg("Requests in queue: " +
                d.device_request_queue.length);
            0 < d.device_request_queue.length && (dbg("Executing next request..."), d.device_request_queue[0].execute())
        };
        this.queueRequest = function(a) {
            dbg("Queueing request " + a.type + ": " + d.device_request_queue.length);
            d.device_request_queue.push(a);
            1 === d.device_request_queue.length && a.execute()
        };
        this.onStatusResponse = function(a) {
            dbg("received status response");
            var f = void 0;
            try {
                f = new g.Printer.Status(a)
            } catch (k) {
                a = d.device_request_queue[0], a.error(k), d.executeNextRequest()
            }
            for (; 0 < d.device_request_queue.length;)
                if (a =
                    d.device_request_queue[0], "status" === a.type) dbg("delivering status..."), a.success(f), d.device_request_queue.shift();
                else {
                    dbg("delivered to all status requests.");
                    break
                } d.executeRequest()
        };
        this.onResponse = function(a, f) {
            dbg("received info response");
            var b = d.device_request_queue[0];
            if (void 0 != f) try {
                a = new f(a)
            } catch (m) {
                b.error && b.error(m);
                d.executeNextRequest();
                return
            }
            b.success && b.success(a);
            d.executeNextRequest()
        };
        this.onSGDResponse = function(a) {
            dbg("received sgd response");
            d.onResponse(a)
        };
        this.onInfoResponse =
            function(a) {
                dbg("received info response");
                d.onResponse(a, g.Printer.Info)
            };
        this.onConfigurationResponse = function(a) {
            dbg("received config response");
            try {
                d.configuration = new g.Printer.Configuration(a)
            } catch (f) {}
            d.onResponse(a, g.Printer.Configuration)
        };
        this.setSGD = function(a, f, b, c) {
            if (!b && !c) return new Promise(function(b, c) {
                d.setSGD(a, f, b, c)
            });
            d.send('! U1 setvar "' + a + '" "' + f + '"\r\n', b, c)
        };
        this.getSGD = function(a, b, c) {
            if (!b && !c) return new Promise(function(b, f) {
                d.getSGD(a, b, f)
            });
            b = new this.Request("sgd",
                '! U1 getvar "' + a + '"\r\n', this.onSGDResponse, b, c);
            this.queueRequest(b)
        };
        this.setThenGetSGD = function(a, b, c, e) {
            if (!c && !e) return new Promise(function(f, c) {
                d.setThenGetSGD(a, b, f, c)
            });
            this.setSGD(a, b, function() {
                d.getSGD(a, c, e)
            }, e)
        };
        this.getInfo = function(a, b) {
            if (!a && !b) return new Promise(function(a, b) {
                d.getInfo(a, b)
            });
            a = new this.Request("info", "~hi\r\n", this.onInfoResponse, a, b);
            this.queueRequest(a)
        };
        this.getConfiguration = function(a, b) {
            if (!a && !b) return new Promise(function(a, b) {
                d.getConfiguration(a, b)
            });
            a = new this.Request("config", "^XA^HH^XZ", this.onConfigurationResponse, a, b);
            this.queueRequest(a)
        };
        this.getStatus = function(a, b) {
            if (!a && !b) return new Promise(function(a, b) {
                d.getStatus(a, b)
            });
            a = new this.Request("status", "~hs\r\n", this.onStatusResponse, a, b);
            d.queueRequest(a)
        };
        this.query = function(a, b, c) {
            if (!b && !c) return new Promise(function(b, c) {
                d.query(a, b, c)
            });
            b = new this.Request("", a, this.onResponse, b, c);
            this.queueRequest(b)
        };
        this.isPrinterReady = function(a, b) {
            if (!a && !b) return new Promise(function(a, b) {
                d.isPrinterReady(a,
                    b)
            });
            this.getStatus().then(function(c) {
                c.isPrinterReady() ? a(c.getMessage()) : b(c.getMessage())
            })
        };
        this.printImageAsLabel = function(a, c, e, g) {
            if (!e && !g) return new Promise(function(b, f) {
                d.printImageAsLabel(a, c, b, f)
            });
            b().then(function(b) {
                c.fitTo = {
                    width: b.printWidth,
                    height: b.labelLength
                };
                c.action = "print";
                BrowserPrint.convert(a, d, c, e, g)
            }).catch(g)
        };
        this.getConvertedResource = function(a, c, e, g) {
            if (!e && !g) return new Promise(function(b, f) {
                d.getConvertedResource(a, c, b, f)
            });
            b().then(function(b) {
                c.action = "return";
                BrowserPrint.convert(a, d, c, e, g)
            }).catch(g)
        };
        this.storeConvertedResource = function(a, c, e, g) {
            if (!e && !g) return new Promise(function(b, e) {
                d.storeConvertedResource(a, c, b, e)
            });
            b().then(function(b) {
                c.action = "store";
                BrowserPrint.convert(a, d, c, e, g)
            }).catch(g)
        };
        var b = function() {
            return new Promise(function(a, b) {
                if (d.configuration) a(d.configuration);
                else return d.getConfiguration().then(function(a) {
                    d.configuration = a;
                    return d.configuration
                }).catch(function(a) {
                    b(a)
                })
            })
        };
        this.configTimeout = function() {
            d.configuration ||
                d.getConfiguration().then(function(a) {
                    return d.configuration = a
                }).catch(function() {
                    setTimeout(d.configTimeout, 1E3)
                })
        };
        this.configTimeout()
    };
    g.watch = function(c, d, b) {
        b || (b = 2);
        l[c.uid] = {
            device: c,
            status: "",
            onchange: d,
            errors: 0,
            errorsForOffline: b
        }
    };
    g.stopWatching = function(c) {
        delete l[c.uid]
    };
    return g
}();

export {
    BrowserPrint,
    Zebra
}